<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr v-for="(tr, i) in responseData.records" :key="i">
          <!-- <vs-td>
            <div class="flex gap-3">
              <vx-tooltip style="width: fit-content" text="Approve Reversal">
                <vs-button
                  color="danger"
                  type="line"
                  icon-pack="feather"
                  icon="icon-check"
                  @click="handleShow(tr)"
                />
              </vx-tooltip>
              <vx-tooltip style="width: fit-content" text="Reject Reversal">
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="handleShowReject(tr)"
                />
              </vx-tooltip>
            </div>
          </vs-td> -->
          <vs-td>
            <vx-tooltip style="width: fit-content" text="View">
              <vs-button
                color="green"
                type="line"
                icon-pack="feather"
                icon="icon-eye"
              /> </vx-tooltip
          ></vs-td>
          <vs-td>{{ tr.Code }}</vs-td>
          <vs-td>
            {{ tr.OperatingUnitCode + " - " + tr.OperatingUnitName }}
          </vs-td>
          <vs-td>
            {{ determineWriteOffType(tr.TransactionType) }}
          </vs-td>
          <vs-td>{{ tr.Note }}</vs-td>
          <vs-td> {{ dateFormat(tr.CreatedAt) }}<br /> </vs-td>
        </vs-tr>
      </template>
    </data-table>
    <vs-prompt
      title="Confirmation"
      color="danger"
      @accept="handleApprove(selectedData)"
      @cancel="handleClose"
      @close="handleClose"
      :buttons-hidden="false"
      :active.sync="showApprovalPrompt"
    >
      <div class="flex">
        <p>
          Are you sure you want to reverse
          <span class="font-bold">{{ selectedData.Code }}</span>
        </p>
      </div>
    </vs-prompt>
    <vs-prompt
      title="Confirmation"
      @accept="handleReject(selectedData)"
      @cancel="handleCloseReject"
      @close="handleCloseReject"
      :buttons-hidden="false"
      :active.sync="showRejectPrompt"
    >
      <div class="flex">
        <p>
          Are you sure you want to reject
          <span class="font-bold">{{ selectedData.Code }}</span>
        </p>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      responseData: {},
      selectedData: {},
      reversalDate: "",
      showApprovalPrompt: false,
      showRejectPrompt: false,
      params: {
        search: "",
        length: 5,
        page: 1,
        order: "write_offs.id",
        sort: "desc",
      },
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
        {
          text: "Code",
          value: "Code",
        },
        {
          text: "Operatring Unit",
          value: "Operatring Unit",
        },
        {
          text: "Transaction Type",
          value: "Transaction Type",
        },
        {
          text: "Note",
          value: "Note",
        },
        {
          text: "Created Date",
          value: "Created Date",
        },
      ],
    };
  },
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.records;
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/write-off/data-table", {
            params: {
              status: 6,
              is_view: 1,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    dateFormat(value) {
      return moment.utc(value).format("DD/MM/YYYY");
    },
    handleShow(tr) {
      this.selectedData = tr;
      this.showApprovalPrompt = true;
    },
    handleClose() {
      this.selectedData = false;
      this.showApprovalPrompt = false;
    },
    handleShowReject(tr) {
      this.selectedData = tr;
      this.showRejectPrompt = true;
    },
    handleCloseReject() {
      this.selectedData = false;
      this.showRejectPrompt = false;
    },
    handleReject(selected) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/write-off/update-status/${selected.ID}`, {
          status: 4, //released
          reversal_date: moment().format("YYYY-MM-DD"),
        })
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    determineWriteOffType(type) {
      // <span v-if="tr.TransactionType == '01'"> AR Invoice </span>
      //     <span v-else-if="tr.TransactionType == '02'"> Customer Deposit </span>
      //     <span v-else-if="tr.TransactionType == '03'"> Credit Note </span>
      //     <span v-else> - </span>
      switch (type) {
        case "01":
          return "AR Invoice";
        case "02":
          return "Customer Deposit";
        case "03":
          return "Credit Note";
        default:
          return "-";
      }
    },
    handleApprove(selected) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/write-off/update-status/${selected.ID}`, {
          status: 6,
        })
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
  },
};
</script>
